import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./vsevolozhsk.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";

export const Vsevolozhsk = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(breakpoints.mobile);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className={s.vsevolozhsk}>
      <Mainscreen
        path={`projects/vsevolozhsk/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
      />
      <div className="container">
        <div className={s.buttons_mobile}>
          <div>настольная игра</div>
          <div>AR</div>
        </div>
        <div className={s.about}>
          <div className={s.about_left}>
            <div className={s.about_left_title} data-aos="fade-right">
              <h2 className={s.about_left_title_name}>
                Большая <br /> бродилка по всеволожскому району
              </h2>
              <span className={s.about_left_title_label}>
                Настольная игра в жанре «Бродилка»
              </span>
            </div>
            <div className={s.buttons}>
              <div className={s.game}>настольная игра</div>
              <div className={s.game}>AR</div>
            </div>
          </div>

          <div className={s.about_content} data-aos="fade-left">
            <h3 className={s.about_content_name}>О проекте:</h3>
            <span className={s.about_content_label}>
              «Большая бродилка» — это настольная игра, в которой вы сможете
              посетить все знаковые достопримечательности Всеволожского района,
              не выходя из дома! Познакомитесь с его историей и культурой,
              узнаете больше о самых актуальных событиях и ключевых артефактах.
              И самое приятное: в это приключение можно взять с собой друзей,
              семью, коллег или школьных товарищей — каждый найдет в игре
              что-то интересное для себя.
            </span>
          </div>
        </div>
        <div className={s.image_1} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/vsevolozhsk/image1.jpg")}
            alt="vsevolozhsk"
          />
        </div>

        <h3 className={s.task_title}>Задачи проекта:</h3>
        <p className={s.task_text}>
          Создать краеведческую настольную игру, посвященную Всеволожскому
          району, которая будет являться образовательно-познавательным подарком
          для пользователей всех возрастов.
        </p>
        <div className={s.task}>
          <div className={s.task_wrapper} data-aos="fade-right">
            <h3 className={s.point_title}>Мы сделали:</h3>
            <ul className={s.point_text}>
              <li>собрали материалы и согласовали их с заказчиком</li>

              <li>
                разработали элементы игры — «Событие», «Хорошо/Плохо»,
                «Мастерская артефактов», внедрили их в игровую механику
              </li>

              <li>создали дизайн всех элементов игры от коробки до буклета</li>
              <li>создали анимации для дополненной реальности (AR)</li>
              <li>разработали мобильное приложение</li>
              <li>полностью проконтролировали процесс печати</li>
            </ul>
          </div>
          <div className={s.task_date} data-aos="fade-left">
            <span className={s.number}>6</span>
            <span className={s.month}>мес.</span>
            <span className={s.label}>
              {t("project.list.kdc.steps.date.label")}
            </span>
          </div>
        </div>
        <div className={s.image_2} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/vsevolozhsk/image2.jpg")}
            alt="vsevolozhsk"
          />
        </div>
        <div className={s.result_content} data-aos="fade-right">
          <h3 className={s.result_content_name}>
            Из чего состоит готовая игра?:
          </h3>
          <span className={s.result_content_label}>
            Большая Бродилка по Всеволожскому району:
            <br />
            <br />
            Игровое поле — интерактивная карта района с  игровым маршрутом
            и находящимися на пути объектами и достопримечательностями
            Всеволожского района, такими как Памятник В. А. Всеволожскому,
            Мемориал «Цветок жизни», Музей «Битва за Ленинград».
            <br />
            <br /> Персонажи —  животные, которые изображены на гербах сельских
            и городских поселений, находящихся на территории Всеволожского
            района.  <br />
            <br />
            Игровая валюта — монеты, используемые на игровых клетках и влияющие
            на победу. <br />
            <br />
            Артефакты — предметы, связанные с Всеволожским районом и событиями,
            которые происходили на его территории —  Печать А.Н. Оленина, Лампа
            Осиновецкого маяка, Паровозная лопата.
            <br />
            <br /> События — факты, связанные с историей Всеволожского района,
            влияющие на ход игры — Строительство Сыроварни — старейшего здания
            в г. Всеволожск, Открытие музейно-мемориального комплекса «Дорога
            Жизни», Фестиваль национальных культур «В гостях у Олениных».
          </span>
        </div>
      </div>
    </section>
  );
};
