import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./Vita.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";

export const Vita = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className={s.hydrocosmos}>
      <Mainscreen
        path={`projects/vita/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
      />
      <div className="container">
        <div className={s.about}>
          <div className={s.about_left}>
            <div className={s.about_left_title} data-aos="fade-right">
              <h2 className={s.about_left_title_name}>
                КОМИКС «Вита будет в порядке»
              </h2>
              <span className={s.about_left_title_label}>
                Социальное графическое произведение<br/> «Вита будет в порядке»
              </span>
            </div>
            <div className={s.buttons}>
              <div className={s.game}>Комикс</div>
            </div>
          </div>

          <div className={s.about_content} data-aos="fade-left">
            <h3 className={s.about_content_name}>О проекте:</h3>
            <span className={s.about_content_label}>
              Данный проект реализован в рамках грантового конкурса Комитета по молодежной политике и взаимодействию
              с общественными организациями при поддержке Санкт-Петербургской общественной организацией «Содействие».
              <br />
              <br />
              Мы объединили свои усилия с Центром «КОНТАКТ» и создали комикс, в котором доступным языком донесли
              информацию о сложных проблемах, актуальных как для подростков, так и для взрослых.
            </span>
          </div>
        </div>
        <div className={s.image_1} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/vita/image1.jpg")}
            alt="hydrocosmos"
          />
        </div>

        <div className={s.task}>
          <div className={s.task_wrapper} data-aos="fade-right">
            <h3 className={s.point_title}>Мы сделали:</h3>
            <ul className={s.point_text}>
              <li>
                разработали концепцию, согласно условиям грантового конкурса и рекомендациям организаторов
              </li>

              <li>
                создали уникальную историю взросления девочки-подростка, которая находится в поиске ответов на знакомые каждому человеку вопросы
              </li>

              <li>
                использовали авторский стиль визуальной подачи эмоций и характеров главных героев, наполнив каждую страницу деталями и символами
              </li>

              <li>
                напечатали тираж и распространили готовый продукт на различных площадках
              </li>
            </ul>
          </div>
          <div className={s.task_date} data-aos="fade-left">
            <span className={s.number}>4</span>
            <span className={s.month}>
              {t("project.list.kdc.steps.date.month")}
            </span>
            <span className={s.label}>
              {t("project.list.kdc.steps.date.label")}
            </span>
          </div>
        </div>
        <div className={s.image_2} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/vita/image2.jpg")}
            alt="hydrocosmos"
          />
        </div>
        <div className={s.result_content} data-aos="fade-right">
          <h3 className={s.result_content_name}>Результаты проекта:</h3>
          <span className={s.result_content_label}>
            Вместе со специалистами из Центра «КОНТАКТ» мы смогли рассказать в доступной форме о деятельности данного
            центра с помощью нашего комикса, который затрагивает важные темы, связанные с принятием решений, а также
            с поддержкой и заботой о себе и других людях.
            <br />
            <br />
            «Вита будет в порядке» – это не только вариант проведения досуга, но и социальное произведение, которое
            оказывает положительное влияние на формирование критического мышления и умения рефлексировать.
          </span>
        </div>
      </div>
    </section>
  );
};
