import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./Arka.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { breakpoints } from "styles/variables";
import { useMediaQuery } from "@mui/material";
import { Helmet } from 'react-helmet';

export const Arka = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(breakpoints.mobile);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen – Мобильное приложение «ARKA»"
        meta={[
          {
            name: "description",
            content:
              "Приложение ARKA - агрегатор AR, внутренняя разработка агентства PushKeen",
          },
        ]}
      />
      <section className={s.hydrocosmos}>
        <Mainscreen
          path={`projects/arka/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
        />
        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div className={s.about_left_title} data-aos="fade-right">
                <h2 className={s.about_left_title_name}>Arka</h2>
                <span className={s.about_left_title_label}>
                  Мобильное приложение «ARKA»
                </span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>Мобильное приложение</div>
                <div className={s.game}>AR (ДОПОЛНЕНННАЯ РЕАЛЬНОСТЬ)</div>
              </div>
            </div>

            <div className={s.about_content} data-aos="fade-left">
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                приложение ARKA - агрегатор AR, внутренняя разработка агентства
                PushKeen. Целью данной программы является возможность быстро
                интегрировать AR контент, без необходимости создавать
                для размещения дополнительное приложение.
                <br />
                <br />
                За основу было взято уже существующее приложение, которое
                не соответствовало современным стандартам, а также
                не использовало возможности динамического AR. Наша задача была
                разработать универсальную программу для любых проектов
                заказчиков, в которых используется технология дополненной
                реальности, чтобы сэкономить их время и бюджет.
              </span>
            </div>
          </div>
          <div className={s.image_1} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/arka/image1.jpg")}
              alt="hydrocosmos"
            />
          </div>
          <div className={s.task}>
            <div className={s.task_wrapper} data-aos="fade-right">
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>
                  приложение с динамическим AR, главное преимущество которого
                  возможность за считанные минуты выгружать контент, при этом
                  без необходимости обновлять его у всех пользователей. Это было
                  достигнуто благодаря серверу, на котором хранятся данные,
                  за счет чего приложение создает AR в режиме реального времени
                </li>

                <li>
                  сделали удобный пользовательский интерфейс, в котором
                  расположено меню с несколькими загруженными проектами, после
                  нажатия на которые открывается подробная информация
                  и скаченный контент
                </li>

                <li>
                  разработали две AR сцены: сцену с image target, позволяющую
                  оживлять картинки, и с plane target, благодаря которой
                  3D модели можно визуализировать на плоской поверхности
                </li>
              </ul>
            </div>
            <div className={s.task_date} data-aos="fade-left">
              <span className={s.number}>6</span>
              <span className={s.month}>
                {t("project.list.kdc.steps.date.month")}
              </span>
              <span className={s.label}>
                {t("project.list.kdc.steps.date.label")}
              </span>
            </div>
          </div>
          <div className={s.image_2} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/arka/image2.jpg")}
              alt="hydrocosmos"
            />
          </div>
          <div className={s.result_content} data-aos="fade-right">
            <h3 className={s.result_content_name}>Результаты проекта:</h3>
            <span className={s.result_content_label}>
              У нас получилось универсальное приложение, позволяющее воплощать
              разные проекты с AR без необходимости создавать новое приложение
              под каждый случай. Использование динамического AR позволило
              нам сделать процесс загрузки контента быстрым и легким за счет
              внешнего сервера. Также приложение получило стильный дизайн
              и удобный интерфейс. Благодаря нему клиенты PushKeen теперь имеют
              возможность использовать AR в своих проектах, не закладывая
              на это колоссальный бюджет и долгие сроки реализации. ARKA сделала
              мир AR доступным как для бизнеса, так и для пользователей!
            </span>
          </div>
        </div>
      </section>
    </>
  );
};
