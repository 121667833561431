import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./Hilkov.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";

export const Hilkov = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className={s.hydrocosmos}>
      <Mainscreen
        path={`projects/hilkov/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
      />
      <div className="container">
        <div className={s.about}>
          <div className={s.about_left}>
            <div className={s.about_left_title} data-aos="fade-right">
              <h2 className={s.about_left_title_name}>
                комикс <br /> «Хилков»
              </h2>
              <span className={s.about_left_title_label}>
                Комикс для РЖД: «Хилков. Легенды Железных Дорог»
              </span>
            </div>
            <div className={s.buttons}>
              <div className={s.game}>Комикс</div>
            </div>
          </div>

          <div className={s.about_content} data-aos="fade-left">
            <h3 className={s.about_content_name}>О проекте:</h3>
            <span className={s.about_content_label}>
              К нам обратились представители компании РЖД с целью совместно
              разработать проект, который будет отражать ценности компании
              и рассказывать о центральной фигуре в истории становления железных
              дорог России — Михаиле Ивановиче Хилкове.
              <br />
              <br />
              Мы решили создать образовательный комикс о событиях жизни министра
              путей сообщения Российской империи, который будет интересен как
              детям, так и взрослым.
            </span>
          </div>
        </div>
        <div className={s.image_1} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/hilkov/image1.jpg")}
            alt="hydrocosmos"
          />
        </div>

        <div className={s.task}>
          <div className={s.task_wrapper} data-aos="fade-right">
            <h3 className={s.point_title}>Мы сделали:</h3>
            <ul className={s.point_text}>
              <li>
                разработали концепцию исходя из поставленной перед нами задачи
              </li>

              <li>
                c нуля разработали сценарий для комикса, который рассказывает
                о том, как Хилков добился успеха, преодолев все сложности
                и препятствия на своем пути
              </li>

              <li>
                создали графику и дизайн для всего иллюстративного материала
              </li>
              <li>совместили авторский стиль и каноны комиксной рисовки</li>
              <li>напечатали тираж комикса и передали компании «РЖД»</li>
            </ul>
          </div>
          <div className={s.task_date} data-aos="fade-left">
            <span className={s.number}>4</span>
            <span className={s.month}>
              {t("project.list.kdc.steps.date.month")}
            </span>
            <span className={s.label}>
              {t("project.list.kdc.steps.date.label")}
            </span>
          </div>
        </div>
        <div className={s.image_2} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/hilkov/image2.jpg")}
            alt="hydrocosmos"
          />
        </div>
        <div className={s.result_content} data-aos="fade-right">
          <h3 className={s.result_content_name}>Результаты проекта:</h3>
          <span className={s.result_content_label}>
            После 4 месяцев работы мы выпустили комикс, который доступен
            к покупке в скоростном поезде «Сапсан».
            <br />
            <br />
            У нас получилось создать яркую, визуально достоверную историю.
            Мы использовали цвета, которые позволяют наилучшим образом передать
            динамику сюжета и эмоциональные сцены. Мы смогли достигнуть
            поставленной цели — сюжет комикса отражает ценности и идентичность
            РЖД, а также повествует о важных для нашей страны событиях
            в увлекательном формате.
          </span>
        </div>
      </div>
    </section>
  );
};
