import { Link } from "react-router-dom";
import s from "./projectCards.module.scss";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import Aos from "aos";
import { useEffect, useState } from "react";
import { CATEGORIES } from "shared/constants/cases";
import { Button } from "@mui/material";

export const ProjectCards = ({ list }) => {
  useEffect(() => {
    Aos.init();
  }, []);

  const [cards, setCards] = useState(list);

  return (
    <section className={s.wr}>
      <Filter setCards={setCards} list={list} />
      <ul className={s.grid}>
        {cards.map((card, i) => (
          <Card key={i} data={card} />
        ))}
      </ul>
    </section>
  );
};

const Card = ({ data }) => {
  return (
    <Link data-aos="fade-up" to={"/projects" + data.route} className={s.card}>
      <li>
        <div className={s.card_image__wr}>
          <img src={require("assets/" + data.imageUrl)} alt={data.name} />
        </div>
        <ul className={s.card_tags}>
          {data.tags.map((tag, i) => (
            <li key={i}>{tag}</li>
          ))}
        </ul>
        <ReactMarkdown className={s.card_title}>
          {"## " + data.name}
        </ReactMarkdown>
      </li>
    </Link>
  );
};

const Filter = ({ setCards, list }) => {
  const [filterTag, setFilterTag] = useState(undefined);

  useEffect(() => {
    if (filterTag) {
      const filteredList = list.filter((item) =>
        item.category.includes(filterTag)
      );
      setCards(filteredList);
    } else setCards(list);
  }, [filterTag]);

  return (
    <ul className={s.filter}>
      <Button
        onClick={() => setFilterTag(undefined)}
        className={filterTag ? "" : s.filter_button__active}
      >
        все
      </Button>
      {Object.keys(CATEGORIES).map((category, i) => (
        <Button
          onClick={() => setFilterTag(CATEGORIES[category])}
          key={i}
          className={
            filterTag === CATEGORIES[category] ? s.filter_button__active : ""
          }
        >
          {CATEGORIES[category]}
        </Button>
      ))}
    </ul>
  );
};
