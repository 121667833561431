import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./Hydrocosmos.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { Helmet } from "react-helmet";

export const Hydrocosmos = () => {
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen – САЙТ «ГИДРОКОСМОС»"
        meta={[
          {
            name: "description",
            content: "Многостраничный сайт для научного журнала «Гидрокосмос»",
          },
        ]}
      />
      <section className={s.hydrocosmos}>
        <Mainscreen path="projects/hydrocosmos/mainscreen.jpg" />
        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div className={s.about_left_title} data-aos="fade-right">
                <h2 className={s.about_left_title_name}>Сайт «Гидрокосмос»</h2>
                <span className={s.about_left_title_label}>
                  Многостраничный сайт для научного журнала <br /> «Гидрокосмос»
                </span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>многостраничный сайт</div>
              </div>
            </div>

            <div className={s.about_content} data-aos="fade-left">
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                Научный журнал «Гидрокосмос» — первое в России междисциплинарное
                издание о&nbsp;подводных исследованиях. <br />
                <br />
                В&nbsp;повестку издания входят теоретические
                и&nbsp;методологические вопросы подводных исследований; проблемы
                морской археологии, связанные с&nbsp;консервацией
                и&nbsp;реставрацией предметов, обнаруженных в&nbsp;воде; вопросы
                сохранности объектов культурного наследия; вопросы технического,
                медицинского, организационного и&nbsp;юридического обеспечения
                подводных археологических работ. <br />
              </span>
            </div>
          </div>
          <div className={s.image_1} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/hydrocosmos/image1.jpg")}
              alt="hydrocosmos"
            />
          </div>
          <p className={s.about_content_label_2}>
            Журнал издается в&nbsp;русской и&nbsp;английской версиях, поскольку
            одной из&nbsp;важнейших своих задач мы&nbsp;считаем продвижение
            российской науки за&nbsp;рубежом и&nbsp;содействие самому широкому
            взаимодействию и&nbsp;научному обмену в&nbsp;среде ученых
            и&nbsp;энтузиастов, связанных с&nbsp;подводной археологией.
          </p>
          <div className={s.task}>
            <div className={s.task_wrapper} data-aos="fade-right">
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>
                  спроектировали CJM сайта и составили техническую документацию
                </li>

                <li>
                  разработали визуальный стиль и веб-дизайн нового сайта <br />
                  За основу дизайна был взят фирменный стиль печатного журнала
                  и доработан для веб-носителей.{" "}
                </li>

                <li>
                  разработали back-end и front-end веб-сайта на технологическом
                  стеке: Next.js, Redux, SCSS, Material UI. Базу данных
                  разработали в PostgreSQL.
                </li>
              </ul>
            </div>
            <div className={s.task_date} data-aos="fade-left">
              <span className={s.number}>3</span>
              <span className={s.month}>
                {t("project.list.kdc.steps.date.month")}
              </span>
              <span className={s.label}>
                {t("project.list.kdc.steps.date.label")}
              </span>
            </div>
          </div>
          <div className={s.image_2} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/hydrocosmos/image2.png")}
              alt="hydrocosmos"
            />
          </div>
          <div className={s.result_content} data-aos="fade-right">
            <h3 className={s.result_content_name}>Результаты проекта:</h3>
            <span className={s.result_content_label}>
              Разработанный дизайн сайта полностью соответствует концепции
              центра подводных исследований Русского географического общества
              «Гидрокосмос», а также удобен и понятен для целевой аудитории
              журнала.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};
