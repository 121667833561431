import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./styles.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Video from "shared/components/Video/Video";
import { Helmet } from 'react-helmet';

export const Bolshaya3d = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen – 3D ДЛЯ БОЛЬШОЙ БРОДИЛКИ"
        meta={[
          {
            name: "description",
            content:
              "Игровые поля для настольной игры «Большая бродилка по Всеволожскому району»",
          },
        ]}
      />
      <section className={s.wr}>
        <Mainscreen
          path={`projects/bolshaya3d/mainscreen${
            isMobile ? "-mobile" : ""
          }.jpg`}
        />
        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div className={s.about_left_title} data-aos="fade-right">
                <h2 className={s.about_left_title_name}>
                  3D для большой бродилки
                </h2>
                <span className={s.about_left_title_label}>
                  Игровые поля для настольной игры «Большая бродилка
                  по Всеволожскому району»
                </span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>3d графика</div>
              </div>
            </div>

            <div className={s.about_content} data-aos="fade-left">
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                Игровое поле и карточки настольной игры «Большая бродилка»
                оживают благодаря технологиям дополненной реальности (AR). Это
                добавляет в игровой процесс увлекательные и интерактивные
                элементы. С помощью мобильного приложения «Мой Всеволожский»
                игроки могут взаимодействовать с виртуальными объектами,
                отвечать на вопросы викторины и зарабатывать дополнительные
                игровые баллы. Каждая 3D-модель уникальна. Игроки могут
                приближать объекты, чтобы изучить их детали и узнать интересные
                факты.
              </span>
            </div>
          </div>

          <div className={s.video_wrapper}>
            <LazyLoadComponent>
              <Video
                videoSrc={"projects/bolshaya3d/1.mp4"}
                poster={"projects/bolshaya3d/poster1.jpg"}
              />
            </LazyLoadComponent>
          </div>
          <div className={s.video_wrapper}>
            <LazyLoadComponent>
              <Video
                videoSrc={"projects/bolshaya3d/2.mp4"}
                poster={"projects/bolshaya3d/poster2.jpg"}
              />
            </LazyLoadComponent>
          </div>
          <div className={s.video_wrapper}>
            <LazyLoadComponent>
              <Video
                videoSrc={"projects/bolshaya3d/3.mp4"}
                poster={"projects/bolshaya3d/poster3.jpg"}
              />
            </LazyLoadComponent>
          </div>

          <div className={s.task}>
            <div className={s.task_wrapper} data-aos="fade-right">
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>
                  Разработали интерактивные элементы и внедрили их в игровой
                  процесс настольной игры
                </li>
              </ul>
            </div>
            <div className={s.task_date} data-aos="fade-left">
              <span className={s.number}>2</span>
              <span className={s.month}>
                {t("project.list.kdc.steps.date.month")}
              </span>
              <span className={s.label}>
                {t("project.list.kdc.steps.date.label")}
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
