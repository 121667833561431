import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./MemoMariinskiy.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";
import Video from "../../../shared/components/Video/Video";
import { Helmet } from "react-helmet";

export const MemoMariinskiy = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen – Мемо «Путешествие по Мариинскому дворцу»"
        meta={[
          {
            name: "description",
            content:
              "«Путешествие по Мариинскому дворцу. История через детали» — это настольная игра в жанре мемори, созданная специально для Законодательного Собрания Санкт-Петербурга.",
          },
        ]}
      />
      <section className={s.mariinskiy}>
        <Mainscreen path={`projects/memoMariinskiy/mainscreen${isMobile ? "-mobile" : ""}.jpg`} />
        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div
                className={s.about_left_title}
                data-aos="fade-right"
              >
                <h2 className={s.about_left_title_name}>
                  Путешествие
                  <br />
                  по Мариинскому дворцу
                </h2>
                <span className={s.about_left_title_label}>Настольная игра в жанре Мемо</span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>Настольная игра</div>
              </div>
            </div>

            <div
              className={s.about_content}
              data-aos="fade-left"
            >
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                «Путешествие по Мариинскому дворцу. История через детали» — это настольная игра в жанре мемори, созданная специально для
                Законодательного Собрания Санкт-Петербурга. 
                <br />
                <br /> В игре используются уникальные снимки величественных залов Мариинского дворца, на основе которых мы создали игровые
                карточки. В буклете отражен экскурсионный маршрут, позволяющий игрокам познакомиться с историей и архитектурой этого
                удивительного места.
              </span>
            </div>
          </div>
          <div
            className={s.image_1}
            data-aos="zoom-in-up"
          >
            <img
              src={require("assets/projects/memoMariinskiy/image1.jpg")}
              alt="Мемо «Путешествие по Мариинскому дворцу»"
            />
          </div>

          <div className={s.task}>
            <div
              className={s.task_wrapper}
              data-aos="fade-right"
            >
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>Организовали и провели фотосъемку залов Мариинского дворца</li>
                <li>Разработали фирменный стиль настольной игры, подготовили дизайн коробки, правил и буклета</li>
                <li>Адаптировали исторические материалы для читателей буклета</li>
                <li>Напечатали тираж 500 шт.</li>
              </ul>
            </div>
            <div
              className={s.task_date}
              data-aos="fade-left"
            >
              <span className={s.number}>4</span>
              <span className={s.month}>{t("project.list.kdc.steps.date.month")}</span>
              <span className={s.label}>{t("project.list.kdc.steps.date.label")}</span>
            </div>
          </div>
          <div
            className={`${s.image_1} ${s.video_container}`}
            data-aos="zoom-in-up"
          >
            <Video
              videoSrc={"projects/memoMariinskiy/video.mp4"}
              poster={"projects/memoMariinskiy/poster.jpg"}
            />
          </div>
          <div
            className={s.result_content}
            data-aos="fade-right"
          >
            <h3 className={s.result_content_name}>Результаты проекта:</h3>
            <span className={s.result_content_label}>
              Настольная игра «Путешествие по Мариинскому дворцу. История через детали»  стала прекрасным подарком для ценителей культуры,
              любителей исторических тем и архитектуры.
              <br />
              <br />
              Сочетание игрового процесса с образовательным содержанием сделало её идеальным выбором для первого знакомства с Мариинским
              дворцом.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};
