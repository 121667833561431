import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./VerniyPut.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";
import { Helmet } from "react-helmet";

export const VerniyPut = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="Комикс «Верный путь»"
        meta={[
          {
            name: "description",
            content: "Графический роман про блокаду Ленинграда",
          },
        ]}
      />
      <section className={s.mariinskiy}>
        <Mainscreen path={`projects/verniyPut/mainscreen${isMobile ? "-mobile" : ""}.jpg`} />
        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div
                className={s.about_left_title}
                data-aos="fade-right"
              >
                <h2 className={s.about_left_title_name}>
                  Комикс
                  <br />
                  «Верный путь»
                </h2>
                <span className={s.about_left_title_label}>
                  Графический роман <br /> про блокаду Ленинграда
                </span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>Комикс</div>
              </div>
            </div>

            <div
              className={s.about_content}
              data-aos="fade-left"
            >
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                Проект был реализован при поддержке Комитета по опеке и попечительству администрации Всеволожского муниципального района,
                поэтому в первую очередь мы сосредоточились на событиях, происходивших в Ленинградской области с 8 сентября 1941 года
                по 27 января 1944 года. Мы постарались отразить все ключевые моменты этого трагического периода: от начала строительства
                Дороги жизни до тяжелейших условий жизни обычных людей и их стойкости перед лицом невзгод.
                <br />
                <br />
                Для главных героев комикса вынужденная остановка на пути во Всеволожск превратилась в обмен историями из прошлого. О том,
                смогла ли случайная встреча оживить память о героях Дороги жизни, Вы сможете узнать, прочитав комикс.
              </span>
            </div>
          </div>
          <div
            className={s.image_1}
            data-aos="zoom-in-up"
          >
            <img
              src={require("assets/projects/verniyPut/image1.jpg")}
              alt="Комикс «Верный путь»"
            />
          </div>

          <div className={s.task}>
            <div
              className={s.task_wrapper}
              data-aos="fade-right"
            >
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>Изучили исторические материалы и создали на их основе уникальную историю и персонажей</li>
                <li>Создали макеты каждой страницы, проработали переходы между фреймами, чтобы обеспечить плавность повествования</li>
                <li>Подготовили графические материалы, уделив особое внимание исторической достоверности</li>
                <li>Добавили диалоги, подобрали шрифты, которые подчеркивали бы настроение сцен</li>
                <li>Провели финальную редактуру, внесли необходимые корректировки, подготовили комикс для печати</li>
              </ul>
            </div>
            <div
              className={s.task_date}
              data-aos="fade-left"
            >
              <span className={s.number}>4</span>
              <span className={s.month}>{t("project.list.kdc.steps.date.month")}</span>
              <span className={s.label}>{t("project.list.kdc.steps.date.label")}</span>
            </div>
          </div>
          <div
            className={s.image_1}
            data-aos="zoom-in-up"
          >
            <img
              src={require("assets/projects/verniyPut/image2.jpg")}
              alt="Комикс «Верный путь»"
            />
          </div>
          <div
            className={s.result_content}
            data-aos="fade-right"
          >
            <h3 className={s.result_content_name}>Результаты проекта:</h3>
            <span className={s.result_content_label}>
              За это время нам удалось создать 32-страничный комикс «Верный путь». История  увидела свет тиражом в 1000 экземпляров. Все
              материалы были направлены комитету по опеке и попечительству администрации Всеволожского муниципального района..
              <br />
              <br />
              Этот комикс — результат кропотливой работы нашей команды, которая стремилась не только подсветить часть реальной истории, но и
              донести ее до широкой аудитории в доступной форме. Мы понимали, что тема блокады Ленинграда является особенно важной и
              значимой, поэтому подошли к воплощению всех идей с особой ответственностью.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};
