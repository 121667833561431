import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./styles.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Video from 'shared/components/Video/Video';
import { Helmet } from 'react-helmet';

export const MemoGorodAnimate = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen – МЕМО «ГОРОД ОТКРЫТИЙ»"
        meta={[
          {
            name: "description",
            content:
              "«Город открытий» — настольная игра, которая знакомит с интерьерами и фасадами достопримечательностей, а также промышленных сооружений Северной столицы.",
          },
        ]}
      />
      <section className={s.wr}>
        <Mainscreen
          path={`projects/memoGorodAnimate/mainscreen${
            isMobile ? "-mobile" : ""
          }.jpg`}
        />
        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div className={s.about_left_title} data-aos="fade-right">
                <h2 className={s.about_left_title_name}>
                  МЕМО «Город открытий»
                </h2>
                <span className={s.about_left_title_label}>
                  Анимированные фотографии
                </span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>2d</div>
              </div>
            </div>

            <div className={s.about_content} data-aos="fade-left">
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                <p>
                  «Город открытий» — настольная игра, которая знакомит
                  с интерьерами и фасадами достопримечательностей, а также
                  промышленных сооружений Северной столицы.
                </p>
                <p>
                  Специально для этого проекта были анимированы картины,
                  написанные тремя уличными художниками: Стасом Багсом, Надеждой
                  Бей и Alesha.
                </p>
              </span>
            </div>
          </div>

          <div className={s.video_wrapper}>
            <LazyLoadComponent>
              <Video
                bgi="projects/memoGorodAnimate/poster1.jpg"
                videoSrc={"projects/memoGorodAnimate/video1.mp4"}
              />
            </LazyLoadComponent>
          </div>
          <div className={s.video_wrapper}>
            <LazyLoadComponent>
              <Video
                bgi="projects/memoGorodAnimate/poster2.jpg"
                videoSrc={"projects/memoGorodAnimate/video2.mp4"}
              />
            </LazyLoadComponent>
          </div>
          <div className={s.video_wrapper}>
            <LazyLoadComponent>
              <Video
                bgi="projects/memoGorodAnimate/poster3.jpg"
                videoSrc={"projects/memoGorodAnimate/video3.mp4"}
              />
            </LazyLoadComponent>
          </div>

          <div className={s.task}>
            <div className={s.task_wrapper} data-aos="fade-right">
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>
                  Создали уникальные анимации для проекта «Открытый город»
                </li>
                <li>Внедрили анимации в приложение PublicARt</li>
              </ul>
            </div>
            <div className={s.task_date} data-aos="fade-left">
              <span className={s.number}>1</span>
              <span className={s.month}>
                {t("project.list.kdc.steps.date.month")}
              </span>
              <span className={s.label}>
                {t("project.list.kdc.steps.date.label")}
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
