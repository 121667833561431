import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./HilkovApp.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";

export const HilkovApp = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className={s.hydrocosmos}>
      <Mainscreen
        path={`projects/hilkovApp/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
      />
      <div className="container">
        <div className={s.about}>
          <div className={s.about_left}>
            <div className={s.about_left_title} data-aos="fade-right">
              <h2 className={s.about_left_title_name}>
                train detective
              </h2>
              <span className={s.about_left_title_label}>
                Визуальная новелла «Train Detective» для РЖД
              </span>
            </div>
            <div className={s.buttons}>
              <div className={s.game}>Мобильная игра</div>
            </div>
          </div>

          <div className={s.about_content} data-aos="fade-left">
            <h3 className={s.about_content_name}>О проекте:</h3>
            <span className={s.about_content_label}>
              «Train Detective» – увлекательная мобильная игра с элементами квеста, созданная специально для РЖД. 
              <br/><br/>
              Главному герою предстоит расследовать преступление, произошедшее в царском поезде. Для этого игроку
              необходимо собирать улики и принимать решения, которые влияют на развитие сюжета. 
              <br/><br/>
              В новеллу также включены мини-игры, которые делают процесс прохождения более интерактивным.
              Продуманный геймплей и впечатляющий визуальный опыт погрузят игроков в атмосферу Российской
              Империи начала XX века.
            </span>
          </div>
        </div>
        <div className={s.image_1} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/hilkovApp/image1.jpg")}
            alt="hydrocosmos"
          />
        </div>
        <div className={s.result_content} data-aos="fade-right">
          <span className={s.result_content_label}>
            *Визуальная новелла — жанр компьютерных игр, в котором зрителю демонстрируется история при помощи вывода
            на экран текста, статичных или анимированных изображений, а также звукового и музыкального сопровождения.
          </span>
        </div>
        <div className={s.task}>
          <div className={s.task_wrapper} data-aos="fade-right">

            <h3 className={s.point_title}>Мы сделали:</h3>
            <ul className={s.point_text}>
              <li>
                придумали концепцию игры с нуля
              </li>

              <li>
                разработали сюжет, связанный c историей железных дорог России
              </li>

              <li>
                подготовили иллюстрации, отражающие эпоху
              </li>
              <li>внедрили в основной геймплей интерактивные мини-игры</li>
              <li>осуществили PR проекта: визуальная новелла была создана специально для выставки-форума “Россия” на
                ВДНХ
              </li>
            </ul>
          </div>
          <div className={s.task_date} data-aos="fade-left">
            <span className={s.number}>6</span>
            <span className={s.month}>
              {t("project.list.kdc.steps.date.month")}
            </span>
            <span className={s.label}>
              {t("project.list.kdc.steps.date.label")}
            </span>
          </div>
        </div>
        <div className={s.image_2} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/hilkovApp/image2.jpg")}
            alt="hydrocosmos"
          />
        </div>
        <div className={s.result_content} data-aos="fade-right">
          <h3 className={s.result_content_name}>Результаты проекта:</h3>
          <span className={s.result_content_label}>
            Мы создали визуальную новеллу, которая будет интересна игрокам любого возраста. Интрига держится
            до последнего благодаря детективным элементам  и возможности напрямую влиять на ход истории.
            Теперь пассажиры компании РЖД точно найдут, как провести время в дороге.
          </span>
        </div>
      </div>
    </section>
  );
};
