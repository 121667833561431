import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./LetnySad.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";
import { Helmet } from "react-helmet";

export const LetniySad = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen – Летний cад"
        meta={[
          {
            name: "description",
            content:
              "Мобильное приложение, которое станет вашим личным гидом по одному из самых знаменитых парковых ансамблей Санкт-Петербурга — Летнему саду. С помощью интерактивной карты можно легко ориентироваться на аллеях парка и исследовать его тайны, узнавая историю скульптур, зданий, фонтанов и других культурных объектов этого поистине удивительного места.",
          },
        ]}
      />
      <section className={s.hydrocosmos}>
        <Mainscreen
          path={`projects/letnySad/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
        />
        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div className={s.about_left_title} data-aos="fade-right">
                <h2 className={s.about_left_title_name}>Летний cад</h2>
                <span className={s.about_left_title_label}>
                  Мобильное приложение-гид по Летнему саду
                </span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>Мобильное приложение</div>
              </div>
            </div>

            <div className={s.about_content} data-aos="fade-left">
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                Мобильное приложение, которое станет вашим личным гидом
                по одному из самых знаменитых парковых ансамблей
                Санкт-Петербурга — Летнему саду. С помощью интерактивной карты
                можно легко ориентироваться на аллеях парка и исследовать
                его тайны, узнавая историю скульптур, зданий, фонтанов и других
                культурных объектов этого поистине удивительного места.
              </span>
            </div>
          </div>
          <div className={s.image_1} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/letnySad/image1.jpg")}
              alt="hydrocosmos"
            />
          </div>

          <div className={s.task}>
            <div className={s.task_wrapper} data-aos="fade-right">
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>
                  создали удобную интерактивную карту, которая позволяет
                  вам спланировать конкретный маршрут прогулки по саду.
                </li>
                <li>
                  систематизировали сами объекты Летнего сада, и добавили
                  к каждому из них фотографию, а также информативное описание.
                </li>
                <li>
                  разработали дизайн приложения, интегрировав его в существующую
                  визуальную концепцию садов Русского музея.
                </li>
                <li>
                  сделали возможность отслеживать все актуальные мероприятия в
                  Летнем саду прямо на интерактивной карте.
                </li>
                <li>
                  внедрили систему «Викторина», где пользователи могут проверить
                  свои знания и ответить на вопросы, связанные с Летним садом.
                </li>
              </ul>
            </div>
            <div className={s.task_date} data-aos="fade-left">
              <span className={s.number}>2</span>
              <span className={s.month}>
                {t("project.list.kdc.steps.date.month")}
              </span>
              <span className={s.label}>
                {t("project.list.kdc.steps.date.label")}
              </span>
            </div>
          </div>
          <div className={s.image_2} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/letnySad/image2.jpg")}
              alt="hydrocosmos"
            />
          </div>
          <div className={s.result_content} data-aos="fade-right">
            <h3 className={s.result_content_name}>Результаты проекта:</h3>
            <span className={s.result_content_label}>
              С нашим мобильным приложением, посетителям Летнего сада
              уже не нужен экскурсовод, ведь всю необходимую информацию
              они без труда найдут на своём электронном носителе. Теперь
              пользователи заранее смогут спланировать любой маршрут и сделать
              свою прогулку по парку максимально познавательной. 
              <br />
              <br />
              Дирекция же Летнего сада получила возможность в лёгкой
              и ненавязчивой форме информировать посетителей сада о предстоящих
              мероприятиях, интегрируя их на интерактивную карту, где можно
              увидеть точки проведения и расписание текущих интерактивов.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};
