import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./SerebryanoeOzherele.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";

export const SerebryanoeOzherele = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className={s.hydrocosmos}>
      <Mainscreen
        path={`projects/SerebryanoeOzherele/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
      />
      <div className="container">
        <div className={s.about}>
          <div className={s.about_left}>
            <div className={s.about_left_title} data-aos="fade-right">
              <h2 className={s.about_left_title_name}>
                комикс «Серебряное ожерелье»
              </h2>
              <span className={s.about_left_title_label}>
                Серия комиксов
              </span>
            </div>
            <div className={s.buttons}>
              <div className={s.game}>Комикс</div>
            </div>
          </div>

          <div className={s.about_content} data-aos="fade-left">
            <h3 className={s.about_content_name}>О проекте:</h3>
            <span className={s.about_content_label}>
              «Петербургский дневник» — это самая массовая и самая цитируемая газета города. Ежедневный тираж
              составляет 150 тысяч экземпляров и распространяется бесплатно. Серия комиксов «Серебряное ожерелье»
              создана совместно с изданием «Петербургский дневник». Это приключенческая история о поисках
              таинственного артефакта, названного так в честь туристического маршрута по Северо-Западным
              регионам России. Сюжет выстроен вокруг истории одной семьи, подчёркивая важность сохранения исторической
              памяти и связи поколений: начиная от древней Руси и заканчивая современным периодом.
            </span>
          </div>
        </div>
        <div className={s.image_1} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/SerebryanoeOzherele/image1.jpg")}
            alt="hydrocosmos"
          />
        </div>

        <div className={s.task}>
          <div className={s.task_wrapper} data-aos="fade-right">
            <h3 className={s.point_title}>Мы сделали:</h3>
            <ul className={s.point_text}>
              <li>
                разработали концепцию и написали сценарий комикса.
              </li>

              <li>
                изучили основные достопримечательности и особенности Северо-Западных регионов России, после чего интегрировали их в сюжет.
              </li>

              <li>
                создали визуальный стиль, графику и дизайн комикса.
              </li>
              <li>подготовили туристическую справку для каждого региона.</li>
            </ul>
          </div>
          <div className={s.task_date} data-aos="fade-left">
            <span className={s.number}> </span>

            <span className={s.label} style={{textAlign: "right"}}>
              Проект реализуется <br/> с апреля по ноябрь <br/> 2024 года

            </span>
          </div>
        </div>
        <div className={s.image_2} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/SerebryanoeOzherele/image2.jpg")}
            alt="hydrocosmos"
          />
        </div>
      </div>
    </section>
  );
};
