import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./NevskyRunner.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";
import { Helmet } from "react-helmet";

export const NevskyRunner = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen – Nevsky Runner"
        meta={[
          {
            name: "description",
            content:
              "Nevsky Runner — это  интерактивный  путеводитель  по  одной  из красивейших  улиц  мира,  который  позволил  игрокам  открыть для  себя  историю  и  культуру  Санкт-Петербурга  в увлекательной  и  доступной  форме.",
          },
        ]}
      />
      <section className={s.hydrocosmos}>
        <Mainscreen
          path={`projects/nevskyRunner/mainscreen${
            isMobile ? "-mobile" : ""
          }.jpg`}
        />
        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div className={s.about_left_title} data-aos="fade-right">
                <h2 className={s.about_left_title_name}>Nevsky Runner</h2>
                <span className={s.about_left_title_label}>Мобильная игра</span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>Мобильные игры</div>
              </div>
            </div>

            <div className={s.about_content} data-aos="fade-left">
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                Nevsky Runner — это  интерактивный  путеводитель  по  одной  из 
                красивейших  улиц  мира,  который  позволил  игрокам  открыть 
                для  себя  историю  и  культуру  Санкт-Петербурга  в 
                увлекательной  и  доступной  форме.
                <br />
                <br />В 2023 году мы  начали  сотрудничество  с  администрацией 
                центрального  района  Санкт-Петербурга,  чтобы  создать 
                интерактивный  проект,  посвященный городу.  Мы  подошли  к 
                разработке  концепции  с  огромным  интересом,  стремясь 
                создать  уникальное предложение,  продуманное  до  мелочей.
                Вместе с заказчиками мы остановились на идее мобильной игры в
                жанре  infinite runner.
              </span>
            </div>
          </div>
          <div className={s.image_1} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/nevskyRunner/image1.jpg")}
              alt="hydrocosmos"
            />
          </div>

          <div className={s.task}>
            <div className={s.task_wrapper} data-aos="fade-right">
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>превратили идею в целостную игровую концепцию.</li>
                <li>
                  разработали уникальные игровые механики, чтобы обеспечить
                  игрокам увлекательный  игровой опыт.
                </li>
                <li>
                  создали персонажей, окружение и музыку, чтобы придать игре
                  жизни.
                </li>
                <li>
                  провели тестирование, чтобы улучшить игру и получить обратную
                  связь от игроков.
                </li>
              </ul>
            </div>
            <div className={s.task_date} data-aos="fade-left">
              <span className={s.number}>8</span>
              <span className={s.month}>
                {t("project.list.kdc.steps.date.month")}
              </span>
              <span className={s.label}>
                {t("project.list.kdc.steps.date.label")}
              </span>
            </div>
          </div>
          <div className={s.image_2} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/nevskyRunner/image2.jpg")}
              alt="hydrocosmos"
            />
          </div>
          <div className={s.result_content} data-aos="fade-right">
            <h3 className={s.result_content_name}>Результаты проекта:</h3>
            <span className={s.result_content_label}>
              Команда  разработчиков  вложила  много  усилий  в  создание 
              захватывающего  игрового  процесса,  атмосферы  и  дизайна
              окружения.  Они  изучали  архитектуру  Невского  проспекта, 
              чтобы  максимально  точно  воссоздать  его  в  игре,  добавляя 
              стилизованные элементы окружения  и  препятствия.  
              <br />
              <br />
              Наши разработчики  также  уделяли  большое внимание  балансу 
              игры,  чтобы  обеспечить  интересный  и  вызывающий  азарт 
              геймплей.  Они  тщательно  работали  над  механикой  бега, 
              управлением  персонажем  и  взаимодействием  с  окружением, 
              чтобы  игра  оставалась  захватывающей  на  протяжении  всего 
              процесса. 
            </span>
          </div>
        </div>
      </section>
    </>
  );
};
