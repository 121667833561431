import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./Grandrims.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { breakpoints } from "styles/variables";
import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";

export const Grandrims = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(breakpoints.mobile);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen – Grandrims"
        meta={[
          {
            name: "description",
            content:
              "Компания “Grandrims” является авторизованными дилерами компании «Ravize», которая производит кованые моноблоки и двухсоставные диски из кованого алюминия 6061T6.",
          },
        ]}
      />
      <section className={s.hydrocosmos}>
        <Mainscreen
          path={`projects/grandrims/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
        />
        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div className={s.about_left_title} data-aos="fade-right">
                <h2 className={s.about_left_title_name}>Grandrims</h2>
                <span className={s.about_left_title_label}>
                  Мобильное приложение “Grandrims”
                </span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>Мобильное приложение</div>
                <div className={s.game}>AR (ДОПОЛНЕНННАЯ РЕАЛЬНОСТЬ)</div>
              </div>
            </div>

            <div className={s.about_content} data-aos="fade-left">
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                Компания “Grandrims” является авторизованными дилерами компании
                «Ravize», которая производит кованые моноблоки и двухсоставные
                диски из кованого алюминия 6061T6. Клиенты компании могут
                прокачать свой автомобиль, улучшив его с помощью кастомных
                дисков, изготовленных по собственному дизайну. Представители
                Grandrims обратились к нам для разработки приложения, в котором
                у пользователей будет возможность примерить модели дисков
                с помощью технологии дополненной реальности.
              </span>
            </div>
          </div>
          <div className={s.image_1} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/grandrims/image1.jpg")}
              alt="hydrocosmos"
            />
          </div>
          {/*<p className={s.about_content_label_2}>*/}
          {/*  Журнал издается в&nbsp;русской и&nbsp;английской версиях, поскольку*/}
          {/*  одной из&nbsp;важнейших своих задач мы&nbsp;считаем продвижение*/}
          {/*  российской науки за&nbsp;рубежом и&nbsp;содействие самому широкому*/}
          {/*  взаимодействию и&nbsp;научному обмену в&nbsp;среде ученых*/}
          {/*  и&nbsp;энтузиастов, связанных с&nbsp;подводной археологией.*/}
          {/*</p>*/}
          <div className={s.task}>
            <div className={s.task_wrapper} data-aos="fade-right">
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>
                  приложение, в котором совмещаются технология компьютерного
                  зрения, распознающая реальные диски на автомобиле,
                  и технология дополненной реальности, позволяющая в режиме
                  реального времени примерить любой контент из приложения
                  <br />
                </li>

                <li>
                  разработали каталог,в котором можно посмотретьи выбрать модели
                  дискови тормозных систем, создали личный кабинет
                  с возможностью добавить свой дизайн дисков. Также сделали
                  AR сцену, где можно примерить на собственный автомобиль
                  понравившиеся модели колесных дисков и тормозных систем,
                  подобрать их размер и цвет.
                  <br />
                </li>

                <li>
                  создали стильный технологичный дизайн, соответствующий образу
                  компании
                </li>
              </ul>
            </div>
            <div className={s.task_date} data-aos="fade-left">
              <span className={s.number}>7</span>
              <span className={s.month}>
                {t("project.list.kdc.steps.date.month")}
              </span>
              <span className={s.label}>
                {t("project.list.kdc.steps.date.label")}
              </span>
            </div>
          </div>
          <div className={s.image_2} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/grandrims/image2.jpg")}
              alt="hydrocosmos"
            />
          </div>
          <div className={s.result_content} data-aos="fade-right">
            <h3 className={s.result_content_name}>Результаты проекта:</h3>
            <span className={s.result_content_label}>
              Приложение для компании “Grandrims” позволяет пользователям
              познакомиться с услугами и продукцией компании, не отходя
              от своего автомобиля. Встроенное компьютерное зрение анализирует
              колеса автомобиля с помощью камеры телефона и, используя
              загруженный в приложении контент, примеряет любые диски
              и тормозные системы. Более того, можно не ограничивать себя
              предложенными вариантами, а менять цвет и форму и загружать свой
              дизайн. Приложение с использованием технологии AR является
              отличным маркетинговым инструментом, позволят заинтересовать
              больше клиентов и создает конкурентное преимущество.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};
