import {useTranslation} from "react-i18next";
import {Mainscreen} from "shared/components/Mainscreen/Mainscreen";
import s from "./BTR.module.scss";
import {useEffect} from "react";
import Aos from "aos";
import {useMediaQuery} from "@mui/material";
import {breakpoints} from "styles/variables";
import Video from "../../../shared/components/Video/Video";
export const BTR = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const {t} = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className={s.hydrocosmos}>
      <Mainscreen
        path={`projects/btr/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
      />
      <div className="container">
        <div className={s.about}>
          <div className={s.about_left}>
            <div className={s.about_left_title} data-aos="fade-right">
              <h2 className={s.about_left_title_name}>
                Back to the Roots
              </h2>
              <span className={s.about_left_title_label}>
                NFT-коллекция 3d камней
              </span>
            </div>
            <div className={s.buttons}>
              <div className={s.game}>3d графика</div>
            </div>
          </div>

          <div className={s.about_content} data-aos="fade-left">
            <h3 className={s.about_content_name}>О проекте:</h3>
            <span className={s.about_content_label}>
              3D модели были разработаны для NFT коллекции фестиваля «Back to the Roots». BTR — масштабный
              арт-перфоманс в Северной Осетии, в ходе которого 10 уличных художников создали легальную галерею
              стрит-арта на Северном Кавказе.
              <br/><br/>
              Мы воссоздали все арт-объекты с помощью 3D, теперь они доступны в виде вращающихся моделей
              камней с анимированными изображениями. На одной стороне изображена работа художника,
              на другой — фотография с фактом о нём. Коллекция представлена на маркетплейсе OpenSea
              и состоит из отдельных уникальных объектов, которые рассказывают о культуре Северной Осетии.
              <br/><br/>
              Средства от продажи работ были направлены на развитие туризма в Республике Северной Осетии-Алании.
            </span>
          </div>
        </div>
        <div className={s.image_1} data-aos="zoom-in-up">
          <Video videoSrc={"projects/btr/video.mp4"} poster={"projects/btr/image1.png"}/>
        </div>
        <div className={s.task}>
          <div className={s.task_wrapper} data-aos="fade-right">
            <h3 className={s.point_title}>Мы сделали:</h3>
            <ul className={s.point_text}>
              <li>
                Разработали 3D модели арт-объектов, которые находятся в труднодоступных местах для привлечения
                внимания к культурному наследию и туристическому сектору Северного Кавказа
              </li>
            </ul>
          </div>
          <div className={s.task_date} data-aos="fade-left">
            <span className={s.number}>2</span>
            <span className={s.month}>
              {t("project.list.kdc.steps.date.month")}
            </span>
            <span className={s.label}>
              {t("project.list.kdc.steps.date.label")}
            </span>
          </div>
        </div>
        <div className={s.image_2} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/btr/image2.jpg")}
            alt="hydrocosmos"
          />
        </div>
      </div>
    </section>
  );
};
