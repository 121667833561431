import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./gorodaPoyezda.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";

export const GorodaPoyezda = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className={s.wr}>
      <Mainscreen
        path={`projects/goroda-poyezda/mainscreen${
          isMobile ? "-mobile" : ""
        }.jpg`}
      />
      <div className="container">
        <div className={s.about}>
          <div className={s.about_left}>
            <div className={s.about_left_title} data-aos="fade-right">
              <h2 className={s.about_left_title_name}>города и поезда</h2>
              <span className={s.about_left_title_label}>
                Карточная игра-викторина для РЖД
              </span>
            </div>
            <div className={s.buttons}>
              <div className={s.game}>Настольная игра</div>
            </div>
          </div>

          <div className={s.about_content} data-aos="fade-left">
            <h3 className={s.about_content_name}>О проекте:</h3>
            <span className={s.about_content_label}>
              По заказу представителей РЖД мы взялись за разработку компактной
              настольной игры, тематически связанной с деятельностью компании.
              <br />
              <br /> Мы адаптировали известную всем игру «Города», превратив
              ее в увлекательную карточную викторину, интересную людям любого
              возраста. Три увлекательных режима нашей игры-викторины позволят
              всем желающим весело провести время и узнать интересные факты
              о городах России.
            </span>
          </div>
        </div>
        <div className={s.image_1} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/goroda-poyezda/image1.jpg")}
            alt="hydrocosmos"
          />
        </div>

        <div className={s.task}>
          <div className={s.task_wrapper} data-aos="fade-right">
            <h3 className={s.point_title}>Мы сделали:</h3>
            <ul className={s.point_text}>
              <li>
                Полностью проконтролировали процесс реализации проекта –
                от разработки концепции с нуля до печати
              </li>
              <li>
                Создали уникальный дизайн в стиле «дудл» для карточек
                «Локомотив» и «Вагон» в расчете на то, что у игроков останется
                положительное впечатление от ее визуальной составляющей.
                На карточках типа «Локомотив» мы запечатлели стилизованные
                модели реально существующих поездов, курсирующих по железным
                дорогам страны
              </li>
              <li>
                С вниманием подошли к созданию контента викторины, чтобы игроки
                смогли получить наибольшее удовольствие от игрового процесса
                и всех его механик, которые мы также самостоятельно разработали
                и протестировали. На обороте каждой карточки с названием города
                мы разместили интересный факт о нем и модификатор хода, который
                делает игру более сложной и интересной
              </li>
            </ul>
          </div>
          <div className={s.task_date} data-aos="fade-left">
            <span className={s.number}>3</span>
            <span className={s.month}>
              {t("project.list.kdc.steps.date.month")}
            </span>
            <span className={s.label}>
              {t("project.list.kdc.steps.date.label")}
            </span>
          </div>
        </div>
        <div className={s.image_2} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/goroda-poyezda/image2.jpg")}
            alt="hydrocosmos"
          />
        </div>
        <div className={s.result_content} data-aos="fade-right">
          <h3 className={s.result_content_name}>Результаты проекта:</h3>
          <span className={s.result_content_label}>
            Взяв за основу классическую версию игры в «Города», мы добавили
            в нее познавательные элементы – таким образом всего за 3 месяца был
            реализован наш проект «Города и поезда». Созданная нами викторина
            мотивирует игроков участвовать в дуэлях и отвечать на вопросы, чтобы
            одержать победу, преследуя главную цель – составить самый длинный
            поезд из карточных вагонов с названиями российских городов.
            <br />
            <br />
            Уникальный дизайн игры – в частности красочные иллюстрации, а также
            увлекательный игровой процесс обязательно приглянутся любому игроку
            и помогут весело провести время в компании.
          </span>
        </div>
      </div>
    </section>
  );
};
