import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./styles.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Video from "shared/components/Video/Video";
import { Helmet } from 'react-helmet';

export const AAG = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen – AAG"
        meta={[
          {
            name: "description",
            content: "Картины для застройщика AAG",
          },
        ]}
      />
      <section className={s.wr}>
        <Mainscreen
          path={`projects/aag/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
        />
        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div className={s.about_left_title} data-aos="fade-right">
                <h2 className={s.about_left_title_name}>
                  Картины для застройщика AAG
                </h2>
                <span className={s.about_left_title_label}>
                  2D картины для AAG
                </span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>2d</div>
              </div>
            </div>

            <div className={s.about_content} data-aos="fade-left">
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                <p>
                  Мы разработали NFT-коллекцию для трёх клубных домов
                  застройщика AAG. Каждая коллекция — «Slash people» & ALTER,
                  «Llifelink» & AMO и «Sensations» & AKZENT — объединяет мир
                  искусства с цифровыми технологиями, создавая новый взгляд
                  на жизнь и бессмертие.
                </p>
                <p>
                  Специально для этого проекта были анимированы картины,
                  написанные тремя уличными художниками: Стасом Багсом, Надеждой
                  Бей и Alesha.
                </p>
              </span>
            </div>
          </div>

          <div className={s.video_wrapper}>
            <LazyLoadComponent>
              <Video
                bgi="projects/aag/poster1.jpg"
                videoSrc={"projects/aag/video1.mp4"}
              />
            </LazyLoadComponent>
          </div>
          <div className={s.video_wrapper}>
            <LazyLoadComponent>
              <Video
                bgi="projects/aag/poster2.jpg"
                videoSrc={"projects/aag/video2.mp4"}
              />
            </LazyLoadComponent>
          </div>
          <div className={s.video_wrapper}>
            <LazyLoadComponent>
              <Video
                bgi="projects/aag/poster3.jpg"
                videoSrc={"projects/aag/video3.mp4"}
              />
            </LazyLoadComponent>
          </div>

          <div className={s.task}>
            <div className={s.task_wrapper} data-aos="fade-right">
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>
                  Создали арт-объекты в виде NFT, которые отразили ценности AAG,
                  а именно – нацеленность на модернизацию сектора недвижимости,
                  цифровизацию, сближение искусства и общества
                </li>
              </ul>
            </div>
            <div className={s.task_date} data-aos="fade-left">
              <span className={s.number}>3</span>
              <span className={s.month}>недели</span>
              <span className={s.label}>
                {t("project.list.kdc.steps.date.label")}
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
