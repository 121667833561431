import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./setka.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "../../../styles/variables";
import { Helmet } from "react-helmet";

export const Setka = () => {
  const { t } = useTranslation();
  const headerStatusMobile = useMediaQuery(breakpoints.mobile);
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen – САЙТ «СЕТКА»"
        meta={[
          {
            name: "description",
            content: "Интернет-магазин для арт-лавки «Сетка»",
          },
        ]}
      />
      <section className={s.setka}>
        {headerStatusMobile ? (
          <Mainscreen path="projects/setka/mainscreenMobile.jpg" />
        ) : (
          <Mainscreen path="projects/setka/mainscreen.jpg" />
        )}

        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div className={s.about_left_title} data-aos="fade-right">
                <h2 className={s.about_left_title_name}>Сайт «сетка»</h2>
                <span className={s.about_left_title_label}>
                  Интернет-магазин для арт-лавки «Сетка»
                </span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>интернет-магазин</div>
                <div className={s.game}>многостраничный сайт</div>
              </div>
            </div>

            <div className={s.about_content} data-aos="fade-left">
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                СЕТКА - это арт-лавка современного искусства художников уличной
                волны. Работы в СЕТКЕ продаются как офлайн в корнере
                на территории фудмолла, так и онлайн на сайте. Поэтому перед
                нами стояла задача создать платформу, на которой можно будет
                узнать все о деятельности проекта (художниках, мероприятиях и
                т.д.) и приобрести работы в интернет-магазине.
              </span>
            </div>
          </div>
          <div className={s.image_1} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/setka/image1.jpg")}
              alt="setka"
            />
          </div>

          <div className={s.task}>
            <div className={s.task_wrapper} data-aos="fade-right">
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>
                  Сделали интернет-магазин, в котором можно ознакомиться
                  с информацией о проекте, прочитать про каждого художника,
                  узнать о предстоящих событиях, приобрести объекты искусства
                  на странице интернет-магазина и получить контактные данные
                </li>

                <li>
                  Разработали уникальный фирменный стиль, отражающий эстетику
                  искусства уличных художников
                </li>

                <li>
                  Разместили удобный интернет-магазин, позволяющий пользователям
                  найти желаемый товар с помощью фильтров, поместить товары
                  в корзину, выбрать удобный способ доставки и оплатить свой
                  заказ онлайн
                </li>
              </ul>
            </div>
            <div className={s.task_date} data-aos="fade-left">
              <span className={s.number}>3</span>
              <span className={s.month}>
                {t("project.list.kdc.steps.date.month")}
              </span>
              <span className={s.label}>
                {t("project.list.kdc.steps.date.label")}
              </span>
            </div>
          </div>
          <div className={s.image_2} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/setka/image2.jpg")}
              alt="setka"
            />
          </div>
          <div className={s.result_content} data-aos="fade-right">
            <h3 className={s.result_content_name}>Результаты проекта:</h3>
            <span className={s.result_content_label}>
              Сайт арт-лавки “СЕТКА” соответствует формату проекта
              как со стороны функционала, так и с визуальной точки зрения.
              Лаконичный, в то же время яркий дизайн позволяет изучить
              информацию о художниках, а затем выбрать и заказать понравившиеся
              работы. Благодаря настроенному интернет-магазину работы резидентов
              СЕТКИ может купить любой желающий по всей России.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};
