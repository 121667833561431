import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./styles.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Video from "shared/components/Video/Video";
import { Helmet } from 'react-helmet';

export const Hraniteli3d = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen – ХРАНИТЕЛИ ВРЕМЕНИ"
        meta={[
          {
            name: "description",
            content:
              "«Хранители времени» — настольная игра об истории СССР 30-80-х годов.",
          },
        ]}
      />
      <section className={s.wr}>
        <Mainscreen
          path={`projects/khraniteli3d/mainscreen${
            isMobile ? "-mobile" : ""
          }.jpg`}
        />
        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div className={s.about_left_title} data-aos="fade-right">
                <h2 className={s.about_left_title_name}>Хранители времени</h2>
                <span className={s.about_left_title_label}>
                  3D-модели для настольной игры
                </span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>3d графика</div>
              </div>
            </div>

            <div className={s.about_content} data-aos="fade-left">
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                «Хранители времени» — настольная игра об истории СССР
                30-80-х годов. В ней используются анимированные 3D-модели,
                интегрированные в мобильное приложение ARKA. Они визуализируют
                исторические события, позволяя сделать процесс игры не только
                увлекательным, но и познавательным.
              </span>
            </div>
          </div>

          <div className={s.video_wrapper}>
            <LazyLoadComponent>
              <Video videoSrc={"projects/khraniteli3d/cosmo.mp4"} />
            </LazyLoadComponent>
          </div>
          <div className={s.video_wrapper}>
            <LazyLoadComponent>
              <Video videoSrc={"projects/khraniteli3d/ping.mp4"} />
            </LazyLoadComponent>
          </div>
          <div className={s.video_wrapper}>
            <LazyLoadComponent>
              <Video videoSrc={"projects/khraniteli3d/t34.mp4"} />
            </LazyLoadComponent>
          </div>

          <div className={s.task}>
            <div className={s.task_wrapper} data-aos="fade-right">
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>
                  Разработали интерактивные элементы и внедрили их в игровой
                  процесс настольной игры
                </li>
              </ul>
            </div>
            <div className={s.task_date} data-aos="fade-left">
              <span className={s.number}>2</span>
              <span className={s.month}>
                {t("project.list.kdc.steps.date.month")}
              </span>
              <span className={s.label}>
                {t("project.list.kdc.steps.date.label")}
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
