import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./dobro.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";

export const Dobro = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(breakpoints.mobile);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className={s.dobro}>
      <Mainscreen
        path={`projects/dobro/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
      />
      <div className="container">
        <div className={s.about}>
          <div className={s.about_left}>
            <div className={s.about_left_title} data-aos="fade-right">
              <h2 className={s.about_left_title_name}>Добро</h2>
              <span className={s.about_left_title_label}>
                Лендинг для PublicArt и Goose Goose
              </span>
            </div>
            <div className={s.buttons}>
              <div className={s.game}>лендинг</div>
            </div>
          </div>

          <div className={s.about_content} data-aos="fade-left">
            <h3 className={s.about_content_name}>О проекте:</h3>
            <span className={s.about_content_label}>
              PublicARt совместно с партнером, рестораном Goose Goose
              от Italy&co, организовал выставку Романа ДОБРО и Алексея Сивака
              в рамках цикла PUB***ART. PUB***ART направлен на интеграцию
              уличных художников в пространства ресторанов и баров. Нам
              предстояло сделать сайт, на котором можно будет ознакомиться
              с творчеством художников, узнать информацию о проекте и приобрести
              работы.
            </span>
          </div>
        </div>
        <div className={s.image_1} data-aos="zoom-in-up">
          <img src={require("assets/projects/dobro/image1.jpg")} alt="dobro" />
        </div>

        <div className={s.task}>
          <div className={s.task_wrapper} data-aos="fade-right">
            <h3 className={s.point_title}>Мы сделали:</h3>
            <ul className={s.point_text}>
              <li>
                сайт с информацией о проекте, с отдельными разделами про каждого
                художника и с каталогом работ
              </li>

              <li>
                разработали индивидуальный дизайн, вдохновившись концепцией
                проекта и стрит-арт культурой
              </li>

              <li>
                оформили отдельный раздел в формате каталога с работами
                художников, в котором можно выбрать понравившуюся картину
                и оформить на нее онлайн-бронирование
              </li>
            </ul>
          </div>
          <div className={s.task_date} data-aos="fade-left">
            <span className={s.number}>2</span>
            <span className={s.month}>нед.</span>
            <span className={s.label}>
              {t("project.list.kdc.steps.date.label")}
            </span>
          </div>
        </div>
        <div className={s.image_2} data-aos="zoom-in-up">
          <img src={require("assets/projects/dobro/image2.jpg")} alt="dobro" />
        </div>
        <div className={s.result_content} data-aos="fade-right">
          <h3 className={s.result_content_name}>Результаты проекта:</h3>
          <span className={s.result_content_label}>
            Мы сделали сайт с индивидуальным дизайном под проект совместной
            выставки двух художников, работы и авторский стиль которых были
            органично интегрированы на страницу при помощи визуальной концепции.
            Также мы сделали доступным выбор и онлайн бронирование работ
            в формате удобного каталога, в котором есть не только возможность
            узнать цену, но и ознакомиться с авторским описание картины.
          </span>
        </div>
      </div>
    </section>
  );
};
