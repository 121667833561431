import {useTranslation} from "react-i18next";
import {Mainscreen} from "shared/components/Mainscreen/Mainscreen";
import s from "./GazpromNCA.module.scss";
import {useEffect} from "react";
import Aos from "aos";
import {useMediaQuery} from "@mui/material";
import {breakpoints} from "styles/variables";
import Video from "../../../shared/components/Video/Video";

export const GazpromNCA = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const {t} = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className={s.hydrocosmos}>
      <Mainscreen
        path={`projects/gazpromNCA/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
      />
      <div className="container">
        <div className={s.about}>
          <div className={s.about_left}>
            <div className={s.about_left_title} data-aos="fade-right">
              <h2 className={s.about_left_title_name}>
                Газпром X NCA
              </h2>
              <span className={s.about_left_title_label}>
                3D видео для Газпрома
              </span>
            </div>
            <div className={s.buttons}>
              <div className={s.game}>3d графика</div>
            </div>
          </div>

          <div className={s.about_content} data-aos="fade-left">
            <h3 className={s.about_content_name}>О проекте:</h3>
            <span className={s.about_content_label}>
              Мы создали видео для коллаборации АЗС Газпромнефть и Новых городских художников.
              <br/><br/>
              При помощи инструмента для трехмерного трекинга мы анимировали кофейные стаканчики и разместили
              их увеличенные модели на знаковых улицах города.
              <br/><br/>
              Использованные иллюстрации были созданы людьми с особенностями здоровья.
            </span>
          </div>
        </div>
        <div className={s.image_1} data-aos="zoom-in-up">
          <Video videoSrc={"projects/gazpromNCA/video.mp4"} poster={"projects/gazpromNCA/image1.png"}/>
        </div>
        <div className={s.task}>
          <div className={s.task_wrapper} data-aos="fade-right">
            <h3 className={s.point_title}>Мы сделали:</h3>
            <ul className={s.point_text}>
              <li>
                Создали рекламное видео при помощью функции 3D Camera Tracker
              </li>
            </ul>
          </div>
          <div className={s.task_date} data-aos="fade-left">
            <span className={s.number}>2</span>
            <span className={s.month}>
              {t("project.list.kdc.steps.date.month")}
            </span>
            <span className={s.label}>
              {t("project.list.kdc.steps.date.label")}
            </span>
          </div>
        </div>
        <div className={s.image_2} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/gazpromNCA/image2.jpg")}
            alt="hydrocosmos"
          />
        </div>
      </div>
    </section>
  );
};
