import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./khraniteli.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";

export const Khraniteli = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(breakpoints.mobile);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className={s.khraniteli}>
      <Mainscreen
        path={`projects/khraniteli/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
      />
      <div className="container">
        <div className={s.buttons_mobile}>
          <div>настольная игра</div>
          <div>AR</div>
        </div>
        <div className={s.about}>
          <div className={s.about_left}>
            <div className={s.about_left_title} data-aos="fade-right">
              <h2 className={s.about_left_title_name}>
                хранители <br /> времени
              </h2>
              <span className={s.about_left_title_label}>
                Настольная игра в жанре «тайм-лайн»
              </span>
            </div>
            <div className={s.buttons}>
              <div className={s.game}>настольная игра</div>
              <div className={s.game}>AR</div>
            </div>
          </div>

          <div className={s.about_content} data-aos="fade-left">
            <h3 className={s.about_content_name}>О проекте:</h3>
            <span className={s.about_content_label}>
              «Хранители времени» — это историческая тайм-лайн игра с 80
              карточками-событиями, которые отражают события с 30-х по 80-е годы
              прошлого века в СССР. Она создана специально для старших классов,
              чтобы погрузить школьников в мир прошлого и вдохновить на изучение
              истории, а также для формирования сообщества единомышленников
              в рамках игры.
            </span>
          </div>
        </div>
        <div className={s.image_1} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/khraniteli/image1.jpg")}
            alt="khraniteli"
          />
        </div>

        <h3 className={s.task_title}>Задачи проекта:</h3>
        <p className={s.task_text}>
          Создать познавательно-образовательную историческую настольную игру для
          старших классов школы. Сделать так, чтобы ученики через игровой опыт
          и соревновательный дух смогли ближе познакомиться с историей,
          погрузиться в события XX века. Передать в игре атмосферу эпохи,
          отображенной на карточках через визуальные средства. А также включить
          в нее все важные события заданного периода.
        </p>
        <div className={s.image_2} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/khraniteli/image2.jpg")}
            alt="khraniteli"
          />
        </div>
        <div className={s.task}>
          <div className={s.task_wrapper} data-aos="fade-right">
            <h3 className={s.point_title}>Мы сделали:</h3>
            <ul className={s.point_text}>
              <li>
                собрали все необходимые исторические данные, после чего провели
                встречи с заказчиком, ветеранами и партнерами для отбора
                собранного материала
              </li>

              <li>
                разработали дизайн всех элементов игры: от коробки до буклета.
                Мы уделяли особое внимание деталям, чтобы создать атмосферу,
                максимально приближенную к конкретной временной эпохе – 30-80
                годам прошлого столетия
              </li>

              <li>
                сделали 3D-модели как элементы дополненной реальности для 10
                карточек, чтобы оживить события и сделать игру более интересной.
                А после внедрили анимацию в приложение ARKA
              </li>
              <li>
                детально проверили результаты проделанной работы, выявили
                недостатки и доработали их
              </li>
            </ul>
          </div>
          <div className={s.task_date} data-aos="fade-left">
            <span className={s.number}>4</span>
            <span className={s.month}>мес.</span>
            <span className={s.label}>
              {t("project.list.kdc.steps.date.label")}
            </span>
          </div>
        </div>
        <div className={s.image_2} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/khraniteli/image3.jpg")}
            alt="khraniteli"
          />
        </div>

        <div className={s.result_content} data-aos="fade-right">
          <h3 className={s.result_content_name}>Результат:</h3>
          <p className={s.result_content_label}>
            Настольная игра «Хранители времени» стала прекрасным дополнением
            для школьных уроков. Интересный дизайн, глубокая наполненность
            и качество материала — мы стремились донести дух того времени
            и значимость истории до каждого участника.
            <br />
            <br />
            Игра будет использоваться в учебных процессах при изучении истории
            и обществознания в старших классах школы, обогащая уроки
            познавательными моментами в интерактивном формате, позволяя
            участникам учиться, играя.
          </p>
        </div>
      </div>
    </section>
  );
};
