import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./nca.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import {useMediaQuery} from "@mui/material";
import {breakpoints} from "../../../styles/variables";

export const Nca = () => {
  const { t } = useTranslation();
  const headerStatusMobile = useMediaQuery(breakpoints.mobile);
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className={s.nca}>
      {headerStatusMobile ?
        <Mainscreen path="projects/nca/mainscreenMobile.jpg" />
        :
        <Mainscreen path="projects/nca/mainscreen.jpg" />
      }

      <div className="container">
        <div className={s.about}>
          <div className={s.about_left}>
            <div className={s.about_left_title} data-aos="fade-right">
              <h2 className={s.about_left_title_name}>Лендинг NCA</h2>
              <span className={s.about_left_title_label}>
                Лендинг для проекта NEW CITY DRIVER
              </span>
            </div>
            <div className={s.buttons}>
              <div className={s.game}>лендинг</div>
            </div>
          </div>

          <div className={s.about_content} data-aos="fade-left">
            <h3 className={s.about_content_name}>О проекте:</h3>
            <span className={s.about_content_label}>
              “NEW CITY DRIVER” - это коллекция машинок от художника Alexx Kool,
              сделанная в целях поддержки творчества художников-аутсайдеров
              и их включенности в повестку современной культуры. В качестве
              источника вдохновения для коллекции выступили культовые
              представители автопрома из разных культурных пространств
              и выдуманных миров. Нашей целью в рамках проекта было создание
              лендинга с информацией о проекте, а также разработка nft
              и размещение токенов на сайт.
            </span>
          </div>
        </div>
        <div className={s.image_1} data-aos="zoom-in-up">
          <img src={require("assets/projects/nca/image1.jpg")} alt="nca" />
        </div>

        <div className={s.task}>
          <div className={s.task_wrapper} data-aos="fade-right">
            <h3 className={s.point_title}>Мы сделали:</h3>
            <ul className={s.point_text}>
              <li>
                лендинг, на главной странице которого расположены разделы: о
                проекте, виды токенов, roadmap и наши проекты. Подобный
                интерфейс позволяет быстро находить необходимую информацию на
                странице сайта
              </li>

              <li>
                для каждой работы художника был зарегистрирован nft, приобретая
                который можно было помочь проекту
              </li>

              <li>
                разработали дизайн и интерфейс, делающие сайт живым и
                интерактивным
              </li>
            </ul>
          </div>
          <div className={s.task_date} data-aos="fade-left">
            <span className={s.number}>2</span>
            <span className={s.month}>
              нед.
            </span>
            <span className={s.label}>
              {t("project.list.kdc.steps.date.label")}
            </span>
          </div>
        </div>
        <div className={s.image_2} data-aos="zoom-in-up">
          <img src={require("assets/projects/nca/image2.jpg")} alt="nca" />
        </div>
        <div className={s.result_content} data-aos="fade-right">
          <h3 className={s.result_content_name}>Результаты проекта:</h3>
          <span className={s.result_content_label}>
            Мы создали лендинг для проекта, разместив на сайте ключевую
            информацию о художнике, его работах и о проекте. Также специально
            для этого проекта была разработа nft коллекция работ художника
            Alexx Kool.
          </span>
        </div>
      </div>
    </section>
  );
};
