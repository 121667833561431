import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./styles.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Video from "shared/components/Video/Video";
import { Helmet } from "react-helmet";

export const MemoVsevAnimate = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen – МЕМО «ВСЕВОЛОЖСК»"
        meta={[
          {
            name: "description",
            content: "Оживленные фотографии исторических мест",
          },
        ]}
      />
      <section className={s.wr}>
        <Mainscreen
          path={`projects/memoVsevAnimate/mainscreen${
            isMobile ? "-mobile" : ""
          }.jpg`}
        />
        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div className={s.about_left_title} data-aos="fade-right">
                <h2 className={s.about_left_title_name}>Мемо «всеволожск»</h2>
                <span className={s.about_left_title_label}>
                  Оживленные фотографии исторических мест
                </span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>2d</div>
              </div>
            </div>

            <div className={s.about_content} data-aos="fade-left">
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                <p>
                  Фотографии архитектуры и скульптур в настольной игре
                  «Всеволожск МЕМО» позволяют исследовать культурное наследие
                  города Всеволожска.
                </p>
                <p>
                  Технологии дополненной реальности создают уникальный опыт
                  и позволяют игрокам буквально «оживить» фотографии
                  исторических мест, сделанные Валентином Блохом.
                </p>
              </span>
            </div>
          </div>

          <div className={s.video_wrapper}>
            <LazyLoadComponent>
              <Video
                bgi="projects/memoVsevAnimate/poster1.jpg"
                videoSrc={"projects/memoVsevAnimate/video1.mp4"}
              />
            </LazyLoadComponent>
          </div>
          <div className={s.video_wrapper}>
            <LazyLoadComponent>
              <Video
                bgi="projects/memoVsevAnimate/poster2.jpg"
                videoSrc={"projects/memoVsevAnimate/video2.mp4"}
              />
            </LazyLoadComponent>
          </div>

          <div className={s.task}>
            <div className={s.task_wrapper} data-aos="fade-right">
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>
                  Анимировали изображения на карточках и при помощи приложения
                  с технологией дополненной реальности сделали ее доступной
                  для пользователя
                </li>
              </ul>
            </div>
            <div className={s.task_date} data-aos="fade-left">
              <span className={s.number}>1</span>
              <span className={s.month}>
                {t("project.list.kdc.steps.date.month")}
              </span>
              <span className={s.label}>
                {t("project.list.kdc.steps.date.label")}
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
