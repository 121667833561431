import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./memoVsevolozhsk.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";

export const MemoVsevolozhsk = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className={s.wr}>
      <Mainscreen
        path={`projects/memovsev/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
      />
      <div className="container">
        <div className={s.about}>
          <div className={s.about_left}>
            <div className={s.about_left_title} data-aos="fade-right">
              <h2 className={s.about_left_title_name}>Мемо.Всеволожск</h2>
              <span className={s.about_left_title_label}>
                Настольная игра в жанре Мемо
              </span>
            </div>
            <div className={s.buttons}>
              <div className={s.game}>Настольная игра</div>
              <div className={s.game}>AR (Дополненная реальность)</div>
            </div>
          </div>

          <div className={s.about_content} data-aos="fade-left">
            <h3 className={s.about_content_name}>О проекте:</h3>
            <span className={s.about_content_label}>
              Настольная игра в популярном жанре Мемо дает возможность узнать
              о достопримечательностях и объектах культурного наследия, которые
              находятся в городе Всеволожск Ленинградской области.
              <br />
              <br /> «МЕМО. Всеволожск» – отличный вариант проведения досуга
              в кругу семьи или вместе с друзьями. Играя, можно не только
              потренировать память, но и погрузиться в историю, оживив
              фотографии уникальных локаций с помощью дополненной реальности
              и изучив справочную информацию в буклете.
            </span>
          </div>
        </div>
        <div className={s.image_1} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/memovsev/image1.jpg")}
            alt="hydrocosmos"
          />
        </div>

        <div className={s.task}>
          <div className={s.task_wrapper} data-aos="fade-right">
            <h3 className={s.point_title}>Мы сделали:</h3>
            <ul className={s.point_text}>
              <li>Определились с концепцией игры</li>
              <li>
                Собрали весь необходимый контент – интересные исторические
                справки, а также вместе с известным фотографом Валентином Блохом
                создали красочные снимки достопримечательностей
              </li>
              <li>Самостоятельно выполнили дизайн всех элементов игры</li>
              <li>
                Разработали 3D графику для дополненной реальности и внедрили
                ее в приложение «Мой Всеволожский»
              </li>
              <li>Проконтролировали процесс печати</li>
            </ul>
          </div>
          <div className={s.task_date} data-aos="fade-left">
            <span className={s.number}>4</span>
            <span className={s.month}>
              {t("project.list.kdc.steps.date.month")}
            </span>
            <span className={s.label}>
              {t("project.list.kdc.steps.date.label")}
            </span>
          </div>
        </div>
        <div className={s.image_2} data-aos="zoom-in-up">
          <img
            src={require("assets/projects/memovsev/image2.jpg")}
            alt="hydrocosmos"
          />
        </div>
        <div className={s.result_content} data-aos="fade-right">
          <h3 className={s.result_content_name}>Результаты проекта:</h3>
          <span className={s.result_content_label}>
            Специально для «МЕМО. Всеволожск» мы выбрали самые интересные
            монументальные сооружения и скульптуры крупнейшего города
            Ленинградской области и перенесли их в игровой мир.
            <br />
            <br />
            Разработанная специально для Администрации Всеволожского района
            настольная игра появилась в учебных учреждениях и  школах города
            и стала подарком для первоклассников и победителей городских
            соревнований. Мы уверены, что «МЕМО. Всеволожск» поможет передать
            историческую память молодым поколениям и привить им любовь к родному
            краю.
          </span>
        </div>
      </div>
    </section>
  );
};
