import { useTranslation } from "react-i18next";
import { Mainscreen } from "shared/components/Mainscreen/Mainscreen";
import s from "./Giri.module.scss";
import { useEffect } from "react";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";
import { Helmet } from "react-helmet";

export const Giri = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen – Giri app"
        meta={[
          {
            name: "description",
            content:
              "Проект, созданный для платформы Giri, – первое в России приложение-карта, призванное познакомить пользователей с социально значимыми предпринимателями.",
          },
        ]}
      />
      <section className={s.hydrocosmos}>
        <Mainscreen
          path={`projects/giri/mainscreen${isMobile ? "-mobile" : ""}.jpg`}
        />
        <div className="container">
          <div className={s.about}>
            <div className={s.about_left}>
              <div className={s.about_left_title} data-aos="fade-right">
                <h2 className={s.about_left_title_name}>Giri App</h2>
                <span className={s.about_left_title_label}>
                  Мобильное приложение «Giri»
                </span>
              </div>
              <div className={s.buttons}>
                <div className={s.game}>Мобильное приложение</div>
              </div>
            </div>

            <div className={s.about_content} data-aos="fade-left">
              <h3 className={s.about_content_name}>О проекте:</h3>
              <span className={s.about_content_label}>
                Проект, созданный для платформы Giri, – первое в России
                приложение-карта, призванное познакомить пользователей с
                социально значимыми предпринимателями.
                <br />
                <br /> Нашей целью было создание продукта, который
                бы способствовал формированию устойчивых связей между частными
                бизнесами и обществом.
              </span>
            </div>
          </div>
          <div className={s.image_1} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/giri/image1.jpg")}
              alt="hydrocosmos"
            />
          </div>

          <div className={s.task}>
            <div className={s.task_wrapper} data-aos="fade-right">
              <h3 className={s.point_title}>Мы сделали:</h3>
              <ul className={s.point_text}>
                <li>
                  собрали полный перечень социальных и локальных
                  предпринимателей, объединили их в одну карту, и сделали
                  возможным поиск по геолокации
                </li>
                <li>
                  разработали удобный интерфейс, позволяющий с легкостью
                  осуществлять процесс поиска, фильтрации и сохранения
                  результатов в отдельную подборку
                </li>
                <li>
                  предусмотрели возможность для пользователей копить баллы
                  и участвовать в специальных мероприятиях
                </li>
              </ul>
            </div>
            <div className={s.task_date} data-aos="fade-left">
              <span className={s.number}>12</span>
              <span className={s.month}>
                {t("project.list.kdc.steps.date.month")}
              </span>
              <span className={s.label}>
                {t("project.list.kdc.steps.date.label")}
              </span>
            </div>
          </div>
          <div className={s.image_2} data-aos="zoom-in-up">
            <img
              src={require("assets/projects/giri/image2.jpg")}
              alt="hydrocosmos"
            />
          </div>
          <div className={s.result_content} data-aos="fade-right">
            <h3 className={s.result_content_name}>Результаты проекта:</h3>
            <span className={s.result_content_label}>
              Приложение для платформы Giri помогает не только отыскать новых
              производителей и открыть для себя уникальную продукцию,
              но и сэкономить благодаря системе скидок и акций
              от предпринимателей.
              <br />
              <br />
              Пользователи могут заранее присмотреть интересные места для
              посещения, используя продвинутый функционал Giri App, который
              позволяет производить поиск по типу малого бизнеса и его
              социальному вкладу. Удобное в использовании мобильное приложение
              повысит уровень доверия общества к предпринимателям, заслуживающим
              внимания.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};
