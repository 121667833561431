import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import s from "./ProjectsPage.module.scss";
import { Helmet } from "react-helmet";
import { ProjectCards } from "shared/components/ProjectCards/ProjectCards";
import { cases } from "shared/constants/cases";

export const ProjectsPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet
        title="PushKeen - Проекты"
        meta={[
          {
            name: "description",
            content: "Проекты компании PushKeen",
          },
        ]}
      />
      <div className="container">
        <section className={s.projects_page}>
          <h2 className={s.title}>{t("projects")}</h2>
          <ProjectCards list={cases} />
        </section>
      </div>
    </>
  );
};
