import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home/Home";
import { Collab } from "./pages/Merch/Collab/Collab";
import { Merch } from "./pages/Merch/Merch";
import { Nft } from "./pages/Nft/Nft";
import { PageNotFound } from "./pages/PageNotFound/PageNotFound";
import { Policy } from "./pages/Policy/Policy";
import { ProjectsPage } from "./pages/ProjectsPage/ProjectsPage";
import { Contact } from "./shared/Contact/Contact";
import {briefRouters, projectRoutes} from "shared/constants/routes";
import { Project } from "pages/Project/Project";
import { PublicArt } from "pages/PublicArt/PublicArt";
import { WebAR } from "pages/WebAR/WebAR";
import { ServicesPage } from "pages/ServicesPage/ServicesPage";
import Header from "shared/Header/Header";
import Footer from "shared/Footer/Footer";
import { DobroWebAR } from "pages/Project/DobroWebAR/DobroWebAR";
import CookieNotification from "./shared/components/CookieNotification";

function App() {
  return (
    <div className="global_container">
      <Header />
      <div className="mobile_container">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
                <Contact />
              </>
            }
          />
          <Route path="nft/*" element={<Nft />} />
          <Route path="/projects">
            <Route index element={<ProjectsPage />} />
            <Route path="publicart" element={<PublicArt />} />
            <Route path="webar" element={<WebAR />} />
            <Route path="dobro" element={<DobroWebAR />} />
            {projectRoutes.map(({ path, locales, Component }) => (
              <Route
                key={path}
                path={path}
                element={
                  <Project locales_page="projects" locales_title={locales}>
                    <Component />
                  </Project>
                }
              />
            ))}
          </Route>
          <Route path="/merch">
            <Route index element={<Merch />} />
            <Route path="nca_publicart" element={<Collab />} />
          </Route>
          <Route path="/services" element={<ServicesPage />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="privacy" element={<Policy />} />
          <Route path="privacy" element={<Policy />} />
          <Route path="brief">
            {briefRouters.map(({ path, locales, Component }) => (
              <Route
                key={path}
                path={path}
                element={<Component />}
              />
            ))}
          </Route>
        </Routes>
      </div>
      <Footer />
      <CookieNotification/>
    </div>
  );
}
export default App;
